@import "./variables.css";

.wrapper {
  width: 80%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 50px 0;
}
/* .wrapperMobile {
  width: 100%;

  margin: 0 auto;
  padding: 10px;

  box-sizing: border-box;
} */
.search_wrapper {
  float: right;
  display: flex;
  justify-content: right;
  width: 50%;
  margin: 20px 0;
  text-align: right;
}

.search_wrapper > input {
  width: 60%;
  padding: 9px 5px;
  border: 1px solid #e1e1e1;
  outline: unset;
}

.schBtn {
  /* height: 35px; */
  border: 1px solid #e1e1e1;
  color: var(--primary-font-color);
  cursor: pointer;
  padding: 8px 16px;
}

.schBtn:hover {
  background: #e1e1e1;
}
.wrapperMobile {
  display: none;
}
.tableFunctionDiv {
  display: flex;
  justify-content: end;
  /* margin-bottom: 12px; */
}
.write_wrapper {
  text-align: right;
  margin-top: 15px;
}
.writeButton {
  width: 80px;
  padding: 10px 0;
  border: 1px solid var(--primary-button-color);
  border-radius: 3px;
  background: #f1f1f1;
  font-weight: 600;
  color: var(--primary-font-color);
  cursor: pointer;
  /* transition: all 0.3s ease-out; */
}
.writeButton:hover {
  background: var(--primary-button-color);
}
.table {
  width: 100%;
  margin: 0 auto;
  border-top: 3px solid #d3d3d3;
  border-bottom: 3px solid #d3d3d3;
  border-collapse: collapse;
}
.table > thead > tr > th {
  padding: 10px;
  background-color: #eee;
  border-bottom: 1px solid #d3d3d3;
}
.table > thead > tr > th:nth-child(1) {
  width: 5%;
}
.table > thead > tr > th:nth-child(2) {
  width: 65%;
}
.table > thead > tr > th:nth-child(3) {
  width: 15%;
}
.table > thead > tr > th:nth-child(4) {
  width: 15%;
}
.table > tbody > tr:hover {
  cursor: pointer;
  background: #eee;
}
.table > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
  text-align: center;
}
.table > tbody > tr > td:nth-child(2) {
  text-align: left;
}

.table > tbody > tr:last-child > td {
  border-bottom: 1px solid transparent;
}

/* Mobile */
@media (max-width: 1023px) {
  .wrapper {
    /* display: none; */
  }
  .tableFunctionDiv {
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
  }
  .table > thead > tr > th {
    font-size: 12px;
  }
  .table > tbody > tr > td {
    font-size: 12px;
  }
  /* .writeButton {
    width: 80px;
    padding: 10px 5px 10px 5px;
    border: 1px solid var(--primary-button-color);
    border-radius: 12px;
    background: none;
    color: var(--primary-button-color);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .writeButton:hover {
    background: var(--primary-button-color);
    color: #fff;
  } */
  /* .articleM {
    padding: 12px 0;
    border-top: 1px solid #d3d3d3;
    cursor: pointer;
  }
  .articleM:last-child {
    border-bottom: 1px solid #d3d3d3;
  }
  .articleM p {
    margin: unset;
    margin-bottom: 12px;
  }
  .articleM span {
    color: #a0a0a0;
    font-size: 0.8rem;
  } */
}
