.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
}
.contents_wrapper > table {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  border-collapse: collapse;
  margin-bottom: 15px;
}
.contents_wrapper > table > tbody > tr:nth-child(1) {
  border-bottom: 1px solid #d3d3d3;
}
.contents_wrapper > table > tbody > tr > td:nth-child(1) {
  min-width: 100px !important;
  border-right: 1px solid #d3d3d3;
  text-align: center;
}
.contents_wrapper > table > tbody > tr > td:nth-child(2) {
  width: 100%;
  padding: 10px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.filesWrapper input {
  width: 78%;
}
.filesWrapper input[type="file"]::file-selector-button {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 6px 13px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}
.filesWrapper input[type="file"]::file-selector-button:hover {
  background: #e1e1e1;
}

.uploadBtn {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 6px 13px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  margin-bottom: 15px;
}
.uploadBtn:hover {
  background: #e1e1e1;
}
