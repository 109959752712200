.text_wrapper {
  margin-bottom: 50px;
}

.sub_title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sub_title span {
  color: var(--primary-first);
}

.text_wrapper table {
  border-spacing: unset;
  margin-bottom: 50px;
}

/* 정보 테이블 */
.info_table {
  /* border: 1px solid #eee; */
  border-collapse: collapse;
}
.info_table td {
  border: 1px solid #ddd;
  padding: 20px;
}
.info_table td:first-child {
  background: #f1f1f1;
  text-align: center;
  width: 25%;
}
/* 정보 테이블 End */

/* 장난감 현황 테이블 */
.toy_table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.toy_table th {
  border: 1px solid #ddd;
  padding: 15px;
  background: #f1f1f1;
}
.toy_table td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
}
.toy_table td:first-child {
  font-weight: 600;
}
.toy_table td:last-child {
  font-weight: 600;
  color: #bd253e;
}
/* 장난감 현황 테이블 End */

.img_wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
}

.img_wrapper > div {
  box-sizing: border-box;
}

.img_wrapper span {
  display: block;
  width: 100%;
  padding: 15px 0;
  text-align: center;

  font-size: 18px;
  font-weight: 500;

  border: 1px solid #e1e1e1;
  
  background: #eee;

  box-sizing: border-box;
}

.img_wrapper img {
  max-width: 100%;
}

@media (max-width: 600px) {
  .img_wrapper {
      grid-template-columns: 1fr;
  }
}