.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.list_title {
  padding: 30px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #999;
}

.list_info {
  margin: 20px 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  /* box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 2px 0px; */
}

.total {
  color: #b0b0b0;
  padding: 12px 20px;
}

.search {
  height: 24px;
  padding: 11px 20px;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 2px 0px;
}

.search > input:focus-visible {
  outline: none;
}

.search > button {
  cursor: pointer;
}

.search > label {
  color: #b0b0b0;
  margin-right: 10px;
}

.search > input {
  border: 0;
  padding: 5px 10px;
  margin-right: 10px;
}

.search > button {
  font-family: "Meiryo";
  background-color: transparent;
  color: #f85f73;
  font-weight: 600;
  font-size: 16px;
  border: 0;
}

.list {
  width: 100%;
  /* margin: 30px 0; */
  border-top: 3px solid #d3d3d3;
  border-bottom: 3px solid #d3d3d3;
  text-align: left;
  border-collapse: collapse;
}

.list > thead > tr > th {
  padding: 10px;
  background-color: #eee;
  border-bottom: 1px solid #d3d3d3;
}

.list > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.list > tbody > tr:last-child > td {
  border-bottom: 1px solid transparent;
}

.id {
  width: 15%;
}

.title {
  color: #333;
  text-decoration: none;
}

.rating,
.date {
  width: 20%;
}

/* Kyeol Custom */

.header_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: right;
  padding: 3px 0;
}

.header_wrapper > span > button {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 6px 13px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.header_wrapper > span > button:hover {
  background: #e3e3e3;
  color: #262626;
}

.search_wrapper {
  width: 50%;
  display: flex;
}

.search_wrapper > input {
  width: 85%;
  outline: none;
  padding: 0 10px;
  border: 1px solid #e1e1e1;
}

.schBtn {
  min-width: 68.55px;
  padding: 5px 3.5%;
  border: 1px solid #e1e1e1;
  color: #333;
  cursor: pointer;
}

.schBtn:hover {
  background: #e1e1e1;
}

/* Kyeol Custom End */
