.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  font-size: 13px;
  border-bottom: 1px solid #dedede;
}

.itemWrapper {
  visibility: hidden;
  width: 220px;
  height: 0px;
  overflow: hidden;
  background: #f2f2f2;
  transition: all 300ms ease;
}
.itemWrapper > div > a {
  display: block;
  position: relative;
  padding: 5px 15px 5px 23px;
  font-size: 13px;
  font-weight: bold;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
