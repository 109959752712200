@font-face {
  font-family: "NotoSansKR";
  src: url("../../fonts/NotoSansKR-VariableFont_wght.ttf") format("truetype");
}

body {
  font-family: "NotoSansKR";
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  cursor: pointer;
  color: #333;
  text-decoration: none;
}

div.wrapper {
  width: 80%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 50px 0px;
}
