.wrapper {
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-bottom: 0;
}
.title {
  margin-bottom: 30px;
}
.inq_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.inq_div > div:last-child {
  width: 90%;
}

.inq_div > div > input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 5px;
  outline: none;
}

.editor_div > div > input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-bottom: none;
  padding: 20px;
  font-size: 16px;
  outline: none;
}

.editor_div > div > input::placeholder {
  font-size: 16px;
}

.hiddenInq {
  width: 10%;
  padding: 20px 0;
  border: 1px solid #e1e1e1;
  margin: 5px auto;
  background: #eee;
}

.submit {
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.submit > div > button {
  width: 10%;
  background: #eee;
  border: 1px solid #e1e1e1;
  padding: 20px;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
}

.submit > div > button:hover {
  background: #e1e1e1;
}

.btn_wrapper {
  margin: 30px 0;
  text-align: center;
}

.btn_wrapper > button {
  /* border: none; */
  background: transparent;
  padding: 6px 13px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.saveBtn {
  color: #379145;
  border: 2px solid #379145;
  transition: 0.1s all ease-in-out;
}

.saveBtn:hover {
  color: #fff;
  background: #379145;
  transition: 0.1s all ease-in-out;
}

.delBtn {
  color: #d53b3b;
  border: 2px solid #d53b3b;
  transition: 0.1s all ease-in-out;
}

.delBtn:hover {
  color: #fff;
  background: #d53b3b;
  transition: 0.1s all ease-in-out;
}
