@import "./variables.css";

.wrapper {
  width: 80%;
  max-width: 1200px;

  margin: 0 auto;
}

.content_wrapper {
  color: var(--primary-font-color);
}
/* 뱌로가기 */
.quickPage {
  display: flex;
  justify-content: center;

  margin-bottom: 50px;

  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.quickPage a {
  display: flex;
  justify-content: space-around;
  width: 33%;
  padding: 12px;

  border-right: 1px solid #ddd;

  box-sizing: border-box;
}

.quickPage a:last-child {
  border-right: none;
}

.quickPage a:hover {
  background: #f1f1f1;
}

.quickPage span {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 150px;

  font-size: 18px;
  font-weight: 600;
}

.quickPage img {
  width: 60px;
  height: 60px;
}
/* 바로가기 End */

/* 장난감 목록 */

.toy_list {
  margin-bottom: 50px;
}

.toy_list_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.toy_list_title > span {
  visibility: hidden;
}
.toy_list_title > h2 {
  text-align: center;
  /* margin-bottom: 20px; */
}
.toy_list_title > a {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  transition: all ease .1s;
}
.toy_list_title > a:hover {
  background: #eee;
  transition: all ease .1s;
}

.toys {
  display: flex;
  justify-content: space-between;
}

.toy {
  /* width: 400px; */
  width: 18%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
  transition: .2s all ease;
}
.toy:hover {
  border: 1px solid rgba(61, 105, 192, 0.5);
  box-shadow: 0 0 10px rgba(61, 105, 192, 0.5);
  transition: .2s all ease;
}
.toy_name_wrapper {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: #fafafa;
  border-bottom: 1px solid #ddd;
}
.toy h2 {
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  color: #333;
  font-weight: 700;
  font-size: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.toy_img_wrapper {
  width: 100%;
  box-sizing: border-box;
}
.toyImg {
  max-width: 200px;
  height: 200px;
}
/* .toy_detail_wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
}
.toy_detail_wrapper p {
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  color: #333;
  margin: unset;
}
.toy_detail_wrapper .toy_category {
  background: #eee;
  border-top: 1px solid #ddd;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}
.toy_detail_wrapper .toy_quantity {
  background: #7cad3a;
  빨강 #c83737
  border-top: 1px solid #72a034;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
}
.toy_detail_wrapper .toy_is_zero {
  background: #ddd;
  border-top: #d1d1d1;
  color: #aaa;
  font-weight: 500;
  font-size: 16px;
} */

/* 장난감 목록 End */
/* 하단 공지사항, 안내사항 */

.noticeAndinfo {
  display: flex;
  /* margin-bottom: 50px; */
  justify-content: space-between;
}

.notice_wrapper {
  width: 65%;
  padding: 20px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.notices {
  padding-top: 20px;
}

.notice {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #ddd;
  
  cursor: pointer;
  box-sizing: border-box;
}

.notice:last-child {
  border-bottom: unset;
}

.notice:hover {
  background: #f1f1f1;
}

.info_wrapper {
  width: 32%;
  padding: 20px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  /* margin-top: 10px; */
  border-spacing: unset;
  border-collapse: collapse;
  font-size: 16px;
  /* margin: 15px 0; */
}
.info td {
  padding: 10px;
  border: 1px solid #ddd;
}
.info td:first-child {
  background: #f1f1f1;
}
.info td span {
  color: var(--primary-first);
}
td.address {
  background: unset !important;
  border: unset;
  font-size: 16px;
  padding-left: unset;
  font-weight: 500;
}

/* 하단 공지사항, 안내사항 End */

@media (max-width: 800px) {
  .quickPage {
    flex-direction: column;
  }
  .quickPage a {
    width: 100%;
    border-right: unset;
    border-bottom: 1px solid #ddd;
  }
  .quickPage a:last-child {
    border-bottom: none;
  }
  .toys {
    flex-direction: column;
  }
  .toy {
    margin-bottom: 15px;
    width: 100%;
  }
  .noticeAndinfo {
    flex-direction: column;
  }
  .notice_wrapper {
    width: 100%;
    margin-bottom: 30px;
  }
  .info_wrapper {
    width: 100%;
  }
}