@import "../styles/variables.css";

.mainImage {
  width: 100%;
}

.title {
  margin: 0 0 20px 0;
}

.searchContainer {
  text-align: center;
  margin-bottom: 20px;

  box-sizing: border-box;
}
.searchContainer input[type="text"] {
  padding: .7rem;
  width: 50%;
  border: 1px solid #ccc;
  outline: 1px solid transparent;
  border-radius: 5px;
  font-size: 1rem;
  transition: all ease 0.3s;

  box-sizing: border-box;
}
.searchContainer input[type="text"]:focus {
  border: 1px solid #2d70c2;
  box-shadow: 0 0 10px rgba(45, 112, 194, 0.5);
  transition: all ease 0.3s;
}
.searchContainer input[type="text"]::placeholder {
  text-align: center;
  font-family: 'NotoSansKR';
}

.toysContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.toy {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
  transition: .2s all ease;
}
.toy:hover {
  border: 1px solid rgba(61, 105, 192, 0.5);
  box-shadow: 0 0 10px rgba(61, 105, 192, 0.5);
  transition: .2s all ease;
}
.toy_name_wrapper {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: #fafafa;
  border-bottom: 1px solid #ddd;
}
.toy h2 {
  margin: 0 auto;
  width: 100%;
  max-width: 250px;
  color: #333;
  font-weight: 700;
  font-size: 20px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.toy_img_wrapper {
  width: 100%;
  box-sizing: border-box;
}
.toyImg {
  max-height: 200px;
  width: auto;
}
.toy_detail_wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
}
.toy_detail_wrapper p {
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  color: #333;
  margin: unset;
}
.toy_detail_wrapper .toy_category {
  background: #eee;
  border-top: 1px solid #ddd;
  color: #333;
  font-weight: 500;
  font-size: 16px;
}
.toy_detail_wrapper .toy_quantity {
  background: #7cad3a;
  /* 빨강 #c83737 */
  border-top: 1px solid #72a034;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.toy_detail_wrapper .toy_is_zero {
  background: #ddd;
  border-top: #d1d1d1;
  color: #aaa;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1500px) {
  .toysContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .toy {
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .searchContainer input[type="text"] {
    width: 100%;
  }
  .toysContainer {
    display: grid;
    grid-template-columns: 1fr;
  }
  .toy {
    width: 100%;
  }
  .toyImg {
    max-width: 150px;
    height: auto;
  }
  .toyDetails h2 {
    font-size: 1rem;
  }
  .toyDetails p {
    font-size: 0.8rem;
    color: #333;
  }
}
