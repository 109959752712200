@import "./variables.css";

.wrapper {
  width: 80%;
  max-width: 1200px;

  margin: 0 auto;
}

/* PC */
.main_img {
  width: 100%;
  padding-bottom: 25%;
  background: #eee;
}

.content_wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
}

.list_title {
  padding: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.content {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  text-align: left;
  border-bottom: 1px solid #999;
}
.header > div {
  padding-top: 14px;
}

.title {
  font-size: 26px;
  font-weight: 600;
  color: #333;
}

.backToList,
.modify {
  margin: 10px;
  font-size: 14px;
  color: #b3b3b3;
}
.backToList:hover,
.modify:hover {
  color: #2155cd;
  cursor: pointer;
}

.date {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #b3b3b3;
}

.main {
  padding: 20px 30px;
}

.chat_logo {
  font-size: 12px;
  color: var(--primary-first);
}

/* 댓글 관련 */
.comment {
  padding: 10px;
  border-bottom: 1px solid #b3b3b3;
}
.myComment {
  background-color: rgba(61, 105, 192, 0.15);
}
.inputComment {
  padding: 18px 15px;
  width: 90%;
}
.inputComment:focus {
  outline: none;
}
/* .textareaComment {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 150px;
  resize: vertical;
}
.textareaComment:focus {
  outline: none;
} */
.textareaWrapper {
  padding: 8px 0;
  display: flex;
}
.saveButton {
  width:10%;
  border: 1px solid #767676;
  border-left: unset;
  background: var(--primary-button-color);
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease-out;
  /* padding: 8px 0px; */
}
.saveButton:hover {
  background: #d4d4d4;
}

.buttonWrapper {
  margin-top: 24px;
  text-align: center;
}
.backToListButton {
  width: 200px;
  padding: 15px 20px 15px 20px;
  border: 1px solid var(--primary-first);
  border-radius: 12px;
  background: none;
  color: var(--primary-first);
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.backToListButton:hover {
  background: var(--primary-first);
  color: #fff;
}

/* Mobile */
@media (max-width: 1023px) {
  .main_img {
    display: none;
  }
  .wrapper {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .content_wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
  }
  .list_title {
    padding: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    color: #333;
  }
  .content {
    width: 100%;
  }
  .header {
    padding: 15px 0px;
    text-align: left;
    border-bottom: 1px solid #999;
  }
  .header > div {
    padding-top: 14px;
  }
  .title {
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }
  .backToList,
  .modify {
    margin-right: 15px;
    font-size: 14px;
    color: #b3b3b3;
  }
  .backToList:hover,
  .modify:hover {
    color: #2155cd;
    cursor: pointer;
  }

  .date {
    font-size: 14px;
    font-weight: 600;
    color: #b3b3b3;
  }

  /* 댓글 관련 */
  .comment {
    padding: 10px;
    border-bottom: 1px solid #b3b3b3;
  }
  .myComment {
    background-color: bisque;
  }
  .inputComment {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 150px;
    resize: vertical;
  }
  .inputComment:focus {
    outline: none;
  }
  .textareaWrapper {
    margin: 8px 0;
  }
}
