.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.table {
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
}
.table > tbody > tr > td:nth-child(1) {
  width: 100px;
  text-align: center;
  background-color: #dadada;
}
.table > tbody > tr > td {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.title {
  font-size: 16px;
}

.content {
  font-size: 16px;
}
.content div {
  padding: unset;
  overflow-y: unset;
}

.activate {
  display: flex;
  /* justify-content: center; */
}
.activate div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.activate button {
  background: #eee;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin-left: 15px;
  cursor: pointer;
}
