@import "./variables.css";

.loginForm {
  width: 100%;
  margin-top: 24px;
  padding: 30px;
  text-align: center;
}
.loginForm > header {
  font-size: 36px;
  font-weight: 1000px;
  margin-bottom: 12px;
}
.loginForm > div {
  display: flex;
  width: 600px;
  margin: 0 auto;
}
.loginForm input[type="text"],
.loginForm input[type="password"] {
  width: 600px;
  height: 60px;
  margin-bottom: 12px;
  padding: 0px 15px;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  font-family: "NotoSansKR";
}
.loginForm input[type="text"]:focus,
.loginForm input[type="password"]:focus {
  outline: unset;
}
.loginForm button {
  width: 600px;
  height: 60px;
  border: unset;
  outline: unset;
  /* border-radius: 12px; */
  background: var(--primary-first);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 3px;
  font-family: "NotoSansKR";
  cursor: pointer;
  /* transition: all 0.2s ease-out; */

  margin-bottom: 24px;
}
.loginForm button:hover {
  background: #3761b3;
}


.socialLoginWrapper {
  display: flex;
  justify-content: center;
}
.socialLoginWrapper > div:nth-child(2) {
  margin: 0px 20px;
}
.socialLoginWrapper img {
  width: 60px;
  height: 60px;
}
/* .socialLoginWrapper > div:hover {
  background: #ddd;
} */

@media (max-width: 1023px) {
  .loginForm {
    padding: unset;
    margin-top: 100px;
  }
  .loginForm > div {
    width: 300px;
  }
  .loginForm button {
    width: 300px;
  }
  /* .socialLoginWrapper {
    width: 300px;
  }
  .socialLoginWrapper img {
    width: 300px;
  } */
}
