.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.table {
  width: 100%;
}
.table td:first-child {
  width: 20%;
  background: #eee;
  padding: 10px 50px;
  text-align: center;
}
.table,
.table td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}
.table input {
  width: 100%;
  box-sizing: border-box;
  border: unset;
  padding: 20px;
  font-size: 16px;
  outline: none;
}

.editorDiv > div > input::placeholder {
  font-size: 16px;
}

.toyNameInput,
.toyThumbnailImageDiv,
.quantityDiv {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-bottom: none;
  padding: 20px;
  font-size: 16px;
  outline: none;
}

.toyThumbnailImageLabel {
  border: 1px solid #ccc;
  cursor: pointer;
}

.toyThumbnailImage {
  max-width: 100px;
}

.submit {
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.submit > div > button {
  width: 10%;
  background: #eee;
  border: 1px solid #e1e1e1;
  padding: 20px;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
}

.submit > div > button:hover {
  background: #e1e1e1;
}
