.headerWrapper {
  width: 100%;
  position: relative;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 4px 0px;
}
.wrapper {
  width: 90%;
}
.headerTopBar,
.headerMenuWrapper,
.headerBar,
.mobileHeaderBar {
  display: none;
}

/* PC 화면 */
@media (min-width: 1024px) {
  .headerTopBar {
    display: block;
    border-bottom: 1px solid #dedede;
  }
  .headerTopBarInner {
    overflow: hidden;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 0;
    text-align: right;
  }
  .headerTopBarList {
    display: inline-block;
    height: 46px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .headerTopBarList > li {
    display: inline-block;
    padding: 16px 0;
    line-height: 12px;
  }
  .headerTopBarList > li > a {
    display: inline-block;
    padding: 0 8px;
    font-size: 12px;
    color: #999999;
    border-right: 1px solid #dedede;
  }
  .headerTopBarList > li:last-child > a {
    border-right: 0;
  }
  .headerBar {
    width: 80%;
    display: flex;
    max-width: 1200px;
    color: #333;
    margin: 0 auto;
  }
  .headerBarInner {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .logo {
    display: flex;
    max-width: 200px;
  }
  .logo > img {
    width: auto;
    max-width: 200px;
    max-height: none;
  }
  .headerMenuWrapper {
    display: flex;
    width: 100%;
    margin: 0 auto;
    min-height: 89px;
    align-items: center;
    justify-content: space-around;
  }
}

/* mobile 화면 */
@media (max-width: 1023px) {
  .mobileHeaderBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: white;

    position: fixed;
    top: 0px;

    z-index: 1000;
  }
  .logo {
    display: flex;
    align-items: center;
  }
  .logo_img {
    /* max-width: 124px; */
    max-height: 35px;
  }

  /* 양쪽 토글 관련 시작 */
  .leftToggle,
  .rightToggle {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;

    color: white;

    cursor: pointer;
  }
  .leftToggle {
    background: #2e2e3a;
  }
  .rightToggle {
    background: var(--primary-first);
  }
  .leftSide {
    position: fixed;
    height: 100vh;
    top: 50px;
    background: white;
    z-index: 990;
    transition: all 300ms ease;
  }
  .rightSide {
    position: fixed;
    height: 100vh;
    top: 50px;
    background: white;
    z-index: 990;
    transition: all 300ms ease;
  }
  .rightSide > div {
    width: 220px;
    border-bottom: 1px solid #dedede;
  }
  .rightSide > div > a {
    height: 42px;
    line-height: 42px;
    padding: 0 35px 0 15px;
    background: #fff;
    font-size: 13px;
    font-weight: bold;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .closeSide {
    visibility: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    border: none;
    background: none;
    text-align: left;
    text-indent: -9999px;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 300ms ease;
  }
  /* 양쪽 토글 관련 끝 */
}
