@import "../../pages/styles/variables.css";

.detail_wrapper {
  width: 20%;
  padding: 12px 0;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  list-style: none;
  transition: 0.1s all ease-in-out;
}

.detail_wrapper:hover {
  color: var(--primary-first);
  transition: 0.1s all ease-in-out;
}

.detail_wrapper > span {
  color: #333;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  transition: 0.1s all ease-in-out;
}

.detail_wrapper:hover > span {
  color: var(--primary-first);
  transition: 0.1s all ease-in-out;
}

.detail_navmenus {
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  top: 100%;
  text-align: left;
  z-index: 100;
  white-space: pre-line;
  background-color: #fff;
  list-style: none;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 4px 0px;
}

.detail_navmenus > li {
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  transition: 0.2s all ease-in-out;
}

.detail_navmenus > li:hover {
  transition: 0.2s all ease-in-out;
  background-color: var(--primary-first);
}

.detail_navmenus > li > a {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px;
  font-size: 14px;
  text-decoration: none;
  color: #333;
  transition: 0.2s all ease-in-out;
  box-sizing: border-box;
}

.detail_navmenus > li:hover > a {
  color: #fff;
}

/* .detail_navmenus > li > a:hover {
  color: #fff;
  background-color: var(--primary-first);
  transition: 0.2s all ease-in-out;
} */

/* .detail_navmenus > li:last-child > a {
  margin-bottom: 5px;
} */
