.tableFunctionDiv {
  display: flex;
  justify-content: end;
  margin-bottom: 12px;
}
.writeButton {
  width: 80px;
  padding: 10px 5px 10px 5px;
  border: 1px solid var(--primary-button-color);
  border-radius: 12px;
  background: none;
  color: var(--primary-button-color);
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.writeButton:hover {
  background: var(--primary-button-color);
  color: #fff;
}

.search_wrapper {
  float: right;
  display: flex;
  justify-content: right;
  width: 50%;
  margin: 20px 0;
  text-align: right;
}

.search_wrapper > input {
  width: 60%;
  padding: 9px 5px;
  border: 1px solid #e1e1e1;
  outline: unset;
}

.schBtn {
  /* height: 35px; */
  border: 1px solid #e1e1e1;
  color: #333;
  cursor: pointer;
  padding: 8px 16px;
}

.schBtn:hover {
  background: #e1e1e1;
}

.table {
  width: 100%;
  margin: 0 auto;
  border-top: 3px solid #d3d3d3;
  border-bottom: 3px solid #d3d3d3;
  border-collapse: collapse;
  text-align: center;
}
.table > thead > tr > th {
  padding: 10px;
  background-color: #eee;
  border-bottom: 1px solid #d3d3d3;
}
.table > thead > tr > th:nth-child(1) {
  width: 5%;
}
.table > thead > tr > th:nth-child(2) {
  width: 75%;
}
.table > thead > tr > th:nth-child(3) {
  width: 20%;
}
.table > tbody > tr:hover {
  cursor: pointer;
  background: #eee;
}
.table > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
}
.table > tbody > tr > td:nth-child(2) {
  text-align: left !important;
}
.table > tbody > tr:last-child > td {
  border-bottom: 1px solid transparent;
}

@media (max-width: 1023px) {
  .tableFunctionDiv {
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
  }
  .writeButton {
    width: 80px;
    padding: 10px 5px 10px 5px;
    border: 1px solid var(--primary-button-color);
    border-radius: 12px;
    background: none;
    color: var(--primary-button-color);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .writeButton:hover {
    background: var(--primary-button-color);
    color: #fff;
  }
  .table {
    width: 100%;
    margin: 0 auto;
    border-top: 3px solid #d3d3d3;
    border-bottom: 3px solid #d3d3d3;
    border-collapse: collapse;
  }
  .table > thead > tr > th {
    padding: 10px;
    background-color: #eee;
    border-bottom: 1px solid #d3d3d3;

    font-size: 15px;
  }
  .table > thead > tr > th:nth-child(1) {
    width: 10%;
  }
  .table > thead > tr > th:nth-child(2) {
    width: 40%;
    text-align: left;
  }
  .table > tbody > tr:hover {
    cursor: pointer;
  }
  .table > tbody > tr > td {
    padding: 10px;
    border-bottom: 1px solid #d3d3d3;

    font-size: 14px;
  }
  .table > tbody > tr:last-child > td {
    border-bottom: 1px solid transparent;
  }
}
