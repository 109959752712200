@import "./variables.css";

.info_wrapper {
  .introduce_wrapper {
    padding: 50px 0;
    color: var(--primary-font-color);
  }
}

.content_wrapper > span {
  display: block;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 30px;
}

.content_wrapper b {
  color: var(--primary-first);
}

.writing > ol {
  list-style-type: decimal;
  font-size: 16px;
  font-weight: 700;
  color: #333 !important;
}
