.Raw {
  cursor: pointer;
}
.Raw:hover {
  background-color: #eee;
}

.AdminLayoutContainer {
  display: flex;
  flex-direction: column;
  min-width: 850px;
  min-height: 100vh;
}
.AdminPanelBodyWrapper {
  display: flex;
  width: 100%;
  user-select: none;
  flex: 1;
}
.AdminPanelContentWrapper {
  width: 85%;
}
