.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.listTable {
  width: 100%;
  border-top: 3px solid #d3d3d3;
  border-bottom: 3px solid #d3d3d3;
  text-align: left;
  border-collapse: collapse;
}

.listTable > thead > tr > th {
  padding: 10px;
  background-color: #eee;
  border-bottom: 1px solid #d3d3d3;
}

.listTable > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.listTable > tbody > tr:last-child > td {
  border-bottom: 1px solid transparent;
}

.id {
  width: 10%;
}
.username {
  width: 30%;
}
.name {
  width: 15%;
}
.permission {
  width: 10%;
}
.dateJoined {
  width: 15%;
}
.lastLogin {
  width: 15%;
}
