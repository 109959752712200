
.tableFunctionDiv {
  display: flex;
  justify-content: end;
  margin-bottom: 12px;
}
.writeButton {
  width: 80px;
  padding: 10px 0;
  border: 1px solid var(--primary-button-color);
  border-radius: 3px;
  background: #f1f1f1;
  font-weight: 600;
  color: var(--primary-font-color);
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.writeButton:hover {
  background: var(--primary-button-color);
}
.table {
  width: 100%;
  margin: 0 auto;
  border-top: 3px solid #d3d3d3;
  border-bottom: 3px solid #d3d3d3;
  border-collapse: collapse;
}
.table > thead > tr > th {
  padding: 10px;
  background-color: #eee;
  border-bottom: 1px solid #d3d3d3;
}
.table > thead > tr > th:nth-child(1) {
  width: 5%;
}
.table > thead > tr > th:nth-child(2) {
  width: 65%;
}
.table > thead > tr > th:nth-child(3) {
  width: 15%;
}
.table > thead > tr > th:nth-child(4) {
  width: 15%;
}
.table > tbody > tr:hover {
  cursor: pointer;
  background: #eee;
}
.table > tbody > tr > td:nth-child(2),
.table > tbody > tr > td:nth-child(3) {
  text-align: center;
}
.table > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
  text-align: center;
}
.table > tbody > tr > td:nth-child(2) {
  text-align: left;
}

.table > tbody > tr:last-child > td {
  border-bottom: 1px solid transparent;
}
.stars {
  color: #ffa500;
}
@media (max-width: 1023px) {
  .tableFunctionDiv {
    display: flex;
    justify-content: end;
    margin-bottom: 12px;
  }
  /* .writeButton {
    width: 80px;
    padding: 10px 5px 10px 5px;
    border: 1px solid var(--primary-button-color);
    border-radius: 12px;
    background: none;
    color: var(--primary-button-color);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .writeButton:hover {
    background: var(--primary-button-color);
    color: #fff;
  } */
  .table {
    width: 100%;
    margin: 0 auto;
    border-top: 3px solid #d3d3d3;
    border-bottom: 3px solid #d3d3d3;
    border-collapse: collapse;
  }
  .table > thead > tr > th {
    padding: 10px;
    background-color: #eee;
    border-bottom: 1px solid #d3d3d3;

    font-size: 15px;
  }
  .table > thead > tr > th:nth-child(1) {
    width: 10%;
  }
  .table > thead > tr > th:nth-child(2) {
    width: 30%;
    text-align: left;
  }
  .table > tbody > tr:hover {
    cursor: pointer;
  }
  .table > tbody > tr > td {
    padding: 10px;
    border-bottom: 1px solid #d3d3d3;

    font-size: 14px;
  }
  .table > tbody > tr > td:last-child {
    font-size: 10px;
  }
  .table > tbody > tr:last-child > td {
    border-bottom: 1px solid transparent;
  }
  .stars > svg {
    width: 10px;
  }
}