@import "./variables.css";

.signUpTable {
  width: 100%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-collapse: collapse;
}
.signUpTable > tr {
  height: 50px;
  border-bottom: 1px solid #ccc;
}
.signUpTable > tr > td:nth-child(1) {
  width: 25%;
  padding: 30px;
}
.signUpTable input {
  border: unset;
}
.signUpTable input:focus {
  outline: unset;
}
.signUpTable span {
  color: red;
}

.buttonWrapper {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}
.buttonWrapper input {
  width: 100px;
  padding: 10px 5px 10px 5px;
  background: var(--primary-button-color);
  border: 1px solid #ddd;
  /* border-radius: 12px; */
  /* background: none; */
  color: var(--primary-buttonFont-color);
  cursor: pointer;
  font-family: "NotoSansKR";
  font-weight: 500;
  font-size: 16px;
  /* transition: all 0.3s ease-out; */
}
.buttonWrapper input:hover {
  background: #ddd;
  color: var(--primary-buttonHoverFont-color)
  /* color: #fff; */
}

.policyWrapper {
  margin-top: 30px;
}
.policyService {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid var(--primary-button-color);
  padding: 10px;
}
.policyPrivacy {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid var(--primary-button-color);
  padding: 10px;
}

@media (max-width: 1023px) {
  .signUpTable > tr > td:nth-child(1) {
    width: 50%;
    padding: 15px;
  }
  .signUpTable span {
    color: red;
    font-size: 12px;
  }
}
