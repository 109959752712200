.sideBarWrapper {
  width: 150px;
  border-right: 1px solid #b0b0b0;
}

.sideBarMenu {
  position: relative;
  margin: 10px 0;
  /* margin-bottom: 20px; */
  padding: 15px;
  color: #606060;
  cursor: pointer;
}
.sideBarMenu:hover {
  background-color: #eee;
}

.title_logo {
  width: 15px;
  margin-right: 10px;
}

.title_logo_selected {
  width: 15px;
  margin-right: 10px;
  color: var(--primary-first);
}

.title {
  font-weight: 600;
}

.title_selected {
  color: var(--primary-first);
  font-weight: 600;
}

.selectedTab {
  background: var(--primary-first);
  padding-bottom: 25%;
}

.sideBarSelected {
  position: absolute;
  top: 0%;
  right: -0.6px;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: var(--primary-first);
  width: 2.5%;
  height: 80%;
  margin: 5.1px 0;
  background-color: var(--primary-first);
}
