.wrapper {
  width: 80%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}
.title {
  margin-bottom: 30px;
}
.starList {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
  border-bottom: unset;
}

.editor_div > div > input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-bottom: none;
  padding: 20px;
  font-size: 16px;
  outline: none;
}

.editor_div > div > input::placeholder {
  font-size: 16px;
}

.hiddenInq {
  width: 10%;
  padding: 20px 0;
  border: 1px solid #e1e1e1;
  margin: 5px auto;
  background: #eee;
}

.submit {
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.submit > div > button {
  width: 10%;
  background: #eee;
  border: 1px solid #e1e1e1;
  padding: 20px;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
}

.submit > div > button:hover {
  background: #e1e1e1;
}

@media (max-width: 1023px) {
  .title {
    font-size: 26px;
  }
  .submit {
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .submit > div > button {
    width: 100px;
    background: #eee;
    border: 1px solid #e1e1e1;
    padding: 20px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
  }

  .submit > div > button:hover {
    background: #e1e1e1;
  }
}
