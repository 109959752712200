@import "./variables.css";

.innerWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tabWrapperMobile {
  display: none;
}

.buttonWrapper {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}
.buttonWrapper > :not(:last-child) {
  margin-right: 12px;
}
.updateButton,
.withdrawButton {
  width: 100px;
  padding: 10px 5px 10px 5px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background: #f1f1f1;
  color: var(--primary-buttonFont-color);
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.updateButton:hover,
.withdrawButton:hover {
  background: #ddd;
}

.tabWrapper {
  width: 15%;
}
.tabWrapper > ul {
  padding: unset;
  list-style: none;
  border-collapse: collapse;
}
.tabWrapper li {
  border: 1px solid #ddd;
  border-bottom: none;
  /* padding: 10px; */
}
.tabWrapper li:last-child {
  border-bottom: 1px solid #ddd;
}
.tabWrapper li:hover {
  background: #f1f1f1;
  cursor: pointer;
}
.tabWrapper li > a {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.selectedTab {
  background: var(--primary-second);
}
.selectedTab:hover {
  background: var(--primary-second) !important;
}
.selectedTab > a {
  color: #fff;
}

.contentWrapper {
  width: 80%;
  margin-top: 16px;
}

/* 회원정보수정 관련 */
.profileTable {
  width: 100%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-collapse: collapse;
}
.profileTable > tr {
  height: 50px;
  border-bottom: 1px solid #ccc;
}
.profileTable > tr > td:nth-child(1) {
  width: 20%;
  padding: 30px;
}
.profileTable input {
  width: 80%;
  max-width: 300px;
  border: 1px solid rgba(153, 179, 228, 0.7);
  background: rgba(153, 179, 228, 0.25);
  outline: unset;
  padding: 13px;
  /* border-bottom: 1px solid #eee; */
}
.profileTable input:focus {
  outline: unset;
}

/* 비밀번호 변경 관련 */
.changePasswordTable {
  width: 100%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-collapse: collapse;
}
.changePasswordTable > tr > td > div {
  display: flex;
  /* width: 100%; */
}
.changePasswordTable > tr > td > div > span {
  width: 200px;
}
.changePasswordTable > tr {
  height: 50px;
  border-bottom: 1px solid #ccc;
}
.changePasswordTable > tr > td:nth-child(1) {
  width: 25%;
  padding: 30px;
}
.changePasswordTable input {
  width: 80%;
  max-width: 300px;
  border: 1px solid rgba(153, 179, 228, 0.7);
  background: rgba(153, 179, 228, 0.25);
  outline: unset;
  padding: 13px;
}
.changePasswordTable input:focus {
  outline: unset;
}

/* 회원탈퇴 관련 */
.withdrawTable {
  width: 100%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  border-collapse: collapse;
}
.withdrawTable > tr > td > div {
  display: flex;
}
.withdrawTable > tr > td > div > span {
  width: 200px;
}
.withdrawTable > tr {
  height: 50px;
  border-bottom: 1px solid #ccc;
}
.withdrawTable > tr > td:nth-child(1) {
  padding: 30px;
}
.withdrawTable input {
  width: 80%;
  max-width: 300px;
  border: 1px solid rgba(153, 179, 228, 0.7);
  background: rgba(153, 179, 228, 0.25);
  outline: unset;
  padding: 13px;
}
.withdrawTable input:focus {
  outline: unset;
}
.withdrawTable input[type="checkbox"] {
  width: 5%;
}
/* 모바일용 */
@media (max-width: 1023px) {
  .innerWrapper {
    display: unset;
  }
  .tabWrapper {
    display: none;
  }
  .tabWrapperMobile {
    display: flex;
    justify-content: space-between;
  }
  .tabWrapperMobile > li {
    list-style: none;
  }
  .tabWrapperMobile > li:not(:last-child) {
    /* padding-right: 12px; */
    /* margin-right: 12px; */
  }
  .contentWrapper {
    width: 100%;
  }

  .profileTable,
  .changePasswordTable,
  .withdrawTable {
    font-size: 12px;
  }
  .profileTable > tr > td:nth-child(1) {
    width: 30%;
  }
  .changePasswordTable > tr > td > div {
    display: unset;
  }
  .changePasswordTable > tr > td:nth-child(1) {
    width: 45%;
  }
  .withdrawTable > tr > td > div {
    display: unset;
  }
}
