.mainBanner {
  position: relative;
  overflow: hidden;
  height: 180px;
}
.mainBanner img {
  max-width: none;
  height: auto;
}
.mainBannerInner {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* box-sizing: border-box; */
}
.mainBannerContent {
  width: 80%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  box-sizing: border-box;
}
.mainBannerContent p {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin: unset;
}

@media (max-width: 1023px) {
  .mainBannerContent {
    width: 100%;
    padding: 10px;
  }
}
