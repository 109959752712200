.footer {
  font-weight: 600;
  background: #fdfdfd;
  border-top: 1px solid #ddd;
}

/* PC 화면 */
@media (min-width: 1024px) {
  .wrapperMobile {
    display: none;
  }
  .wrapperPC {
    width: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: space-evenly;
  }

  .wrapperPC > .logo {
    width: 40%;
    text-align: right;
    padding: 60px 8px;
    /* font-size: 20px; */
    color: var(--primary-first);
    font-weight: 700;
  }

  .logo > img {
    height: 65px;
  }

  .wrapperPC > .break_line {
    width: 2px;
    padding-bottom: 10%;
    background: #eee;
  }

  .wrapperPC > .company_info {
    width: 40%;
    padding: 10px;
    font-size: 14px;
    color: #999;
  }

  .wrapperPC > .company_info > p {
    margin: 0;
  }
}
/* Mobile */
@media (max-width: 1023px) {
  .wrapperPC {
    display: none;
  }
  .wrapperMobile {
    width: 80%;
    padding: 50px 0;
    margin: 0 auto;
  }
  .wrapperMobile > .logo {
    text-align: left;
    color: var(--primary-first);
    font-size: 20px;
    font-weight: 700;
  }
  .wrapperMobile > .company_info {
    font-size: 13px;
    color: #999;
  }

  .wrapperMobile > .company_info > p {
    margin: 0;
  }
}

/* 더 작은 모바일 (iPhone 12 Pro, Galaxy S21 Ultra) */
@media (max-width: 600px) {
  .wrapperMobile > .company_info {
    font-size: 11px;
    color: #999;
  }
  .wrapperMobile > .logo {
    /* width: 40%; */
    text-align: left;
    padding: 20px 8px;
    /* font-size: 20px; */
    color: var(--primary-first);
    font-weight: 700;
  }
  .wrapperMobile > .logo > img {
    width: 50%;
  }
}
