.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.table {
  width: 100%;
}
.table td:first-child {
  width: 20%;
  background: #eee;
  padding: 10px 50px;
  text-align: center;
}
.table,
.table td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}
.table input {
  width: 100%;
  box-sizing: border-box;
  border: unset;
  padding: 20px;
  font-size: 16px;
  outline: none;
}

.editorDiv > div > input::placeholder {
  font-size: 16px;
}

.toyThumbnailImageLabel {
  border: 1px solid #ccc;
  cursor: pointer;
}

.toyThumbnailImage {
  max-width: 100px;
}

.btn_wrapper {
  text-align: center;
}

.btn_wrapper > button {
  /* border: none; */
  background: transparent;
  padding: 6px 13px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.saveBtn {
  color: #379145;
  border: 2px solid #379145;
  transition: 0.1s all ease-in-out;
}

.saveBtn:hover {
  color: #fff;
  background: #379145;
  transition: 0.1s all ease-in-out;
}

.delBtn {
  color: #d53b3b;
  border: 2px solid #d53b3b;
  transition: 0.1s all ease-in-out;
}

.delBtn:hover {
  color: #fff;
  background: #d53b3b;
  transition: 0.1s all ease-in-out;
}
