@import "./variables.css";

.info_wrapper {
  .introduce_wrapper {
    padding: 50px 0;
    color: var(--primary-font-color);
    /* text-align: center; */
  }
}

.content_wrapper {
  /* margin-top: 50px; */
}

.content_wrapper > span {
  display: block;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 30px;
}

.content_wrapper b {
  color: var(--primary-first);
}

.writing > ol {
  list-style-type: decimal;
  font-size: 16px;
  font-weight: 700;
  color: #333 !important;
}

.writing li {
  margin-bottom: 20px;
}

.writing .highlight {

}

.writing .red_highlight {

}

.writing .explanation {
  font-weight: 500;
  color: #888;
  font-size: 14px;
}

.writing .caution {
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}