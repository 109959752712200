.contents_wrapper {
  width: 90%;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
}

.header {
  margin-bottom: 30px;
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  color: #333;
}

.table {
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  margin-bottom: 12px;
}
.table > tbody > tr > td:nth-child(1) {
  width: 100px;
  text-align: center;
  background-color: #dadada;
}
.table > tbody > tr > td {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.btn_wrapper {
  text-align: center;
}

.btn_wrapper > button {
  background: transparent;
  padding: 6px 13px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.saveBtn {
  color: #379145;
  border: 2px solid #379145;
  transition: 0.1s all ease-in-out;
}

.saveBtn:hover {
  color: #fff;
  background: #379145;
  transition: 0.1s all ease-in-out;
}

.delBtn {
  color: #d53b3b;
  border: 2px solid #d53b3b;
  transition: 0.1s all ease-in-out;
}

.delBtn:hover {
  color: #fff;
  background: #d53b3b;
  transition: 0.1s all ease-in-out;
}
