.owl-nav {
  display: flex;
  justify-content: space-between;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 0 30px;
  transform: translateY(-26px);
  margin-top: unset !important;
}

.prev-custom,
.next-custom {
  color: white;
  font-size: 100px;
}

.owl-prev:hover,
.owl-next:hover {
  color: unset !important;
  background: unset !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 50px !important;
  height: 5px !important;
  margin: unset !important;
  border-radius: unset !important;
}
.owl-theme .owl-dots .owl-dot:not(:last-child) {
  margin-right: 10px !important;
}

@media (max-width: 1023px) {
  .prev-custom,
  .next-custom {
    font-size: 50px;
  }
}
