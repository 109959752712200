@import "./variables.css";

.wrapper {
  width: 80%;
  max-width: 1200px;

  margin: 0 auto;
}

/* PC */
  .main_img {
    width: 100%;
    padding-bottom: 25%;
    background: #eee;
  }

  .content_wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
  }

  .list_title {
    padding: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    color: #333;
  }

  .content {
    width: 100%;
    min-height: 500px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
    text-align: left;
    border-bottom: 1px solid #999;
  }
  .header > div {
    padding-top: 14px;
  }

  .title {
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }

  .backToList,
  .modify {
    margin: 10px;
    font-size: 14px;
    color: #b3b3b3;
  }
  .backToList:hover,
  .modify:hover {
    color: #2155cd;
    cursor: pointer;
  }

  .date {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #b3b3b3;
  }

  .main {
    padding: 20px 30px;
  }

  .buttonWrapper {
    margin-top: 24px;
    text-align: center;
  }
  .backToListButton {
    width: 200px;
    padding: 15px 20px 15px 20px;
    border: 1px solid var(--primary-first);
    border-radius: 12px;
    background: none;
    color: var(--primary-first);
    cursor: pointer;
    transition: all 0.1s ease-out;
  }
  .backToListButton:hover {
    background: var(--primary-first);
    color: #fff;
  }

/* Mobile */
@media (max-width: 1023px) {
  .main_img {
    display: none;
  }
  .wrapper {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .content_wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 30px auto;
  }
  .list_title {
    padding: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    color: #333;
  }
  .content {
    width: 100%;
    min-height: 400px;
  }
  .header {
    padding: 15px 0px;
    text-align: left;
    border-bottom: 1px solid #999;
  }
  .header > div {
    padding-top: 14px;
  }
  .title {
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }
  .backToList,
  .modify {
    margin-right: 15px;
    font-size: 14px;
    color: #b3b3b3;
  }
  .backToList:hover,
  .modify:hover {
    color: #2155cd;
    cursor: pointer;
  }

  .date {
    font-size: 14px;
    font-weight: 600;
    color: #b3b3b3;
  }

  /* .buttonWrapper {
    margin-top: 24px;
    text-align: center;
  }
  .backToListButton {
    width: 200px;
    padding: 15px 20px 15px 20px;
    border: 1px solid var(--primary-button-color);
    border-radius: 12px;
    background: none;
    color: var(--primary-button-color);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .backToListButton:hover {
    background: var(--primary-button-color);
    color: #fff;
  } */
}
