:root {
  --primary-first: #3d69c0;
  --primary-second: #99b3e4;
  --primary-button-color: #e1e1e1;
  --primary-buttonFont-color: #666;
  --primary-buttonHoverFont-color: #595959;
  --primary-font-color: #333;
  --content-max-width: 1200px;
}

h1, h2, h3, h4 {
  margin: unset;
}

