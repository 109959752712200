@import "../styles/variables.css";

.mainImage {
  width: 100%;
}

.toy {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border: 1px solid #ddd;
  border-collapse: collapse;
  /* padding: 1rem; */
  /* border-radius: 5px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  margin: 20px 0;
}
.toy_img_wrapper {
  border-right: 1px solid #ddd;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* width: 50%; */
  /* text-align: right; */
}
.toyImg {
  max-height: 200px;
  width: auto;
}
.toy_detail_wrapper {
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 20px;
  /* flex: 1; */
}
.toy_detail_wrapper h2 {
  margin: unset;
  font-size: 1.5rem;
}
.toy_detail_wrapper p {
  font-size: 1rem;
  color: #333;
}

.toyDetailsTable {
  width: 50%;
  border-spacing: unset;
  border-top: 1px solid #ddd;
}

.toyDetailsTable td {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.toyDetailsTable td span {
  margin-left: 5px;
  color: #333;
  font-weight: 600;
}

.toyDetailsTable td:first-child {
  width: 35%;
  color: var(--primary-first);
}
.toyDetailsTable td:last-child {
  width: 65%;
  font-weight: 400;
}

.toyComponents,
.toyContent,
.toyAddress,
.toyCallNumber {
  align-items: center;
  /* border-bottom: 1px dotted #ddd; */
  /* padding: 1rem; */
  margin: 30px 0;

  box-sizing: border-box;
}

/* 구성, 주소, 대여문의 박스 꾸미기 */
.toyComponents > div:nth-child(1),
.toyContent > div:nth-child(1),
.toyAddress > div:nth-child(1),
.toyCallNumber > div:nth-child(1) {
  width: 100px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary-second);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;

  margin-bottom: 20px;
}

.toyContent > div {
  padding: unset;
  overflow-y: unset;
}

.buttonWrapper {
  margin-top: 24px;
  text-align: center;
}
.backToListButton {
  width: 200px;
  padding: 15px 20px 15px 20px;
  border: 1px solid var(--primary-button-color);
  /* border-radius: 12px; */
  background: #eee;
  color: var(--primary-buttonFont-color);
  cursor: pointer;
}
.backToListButton:hover {
  background: var(--primary-button-color);
  color: var(--primary-buttonHoverFont-color);;
}

@media (max-width: 1023px) {
  .toyImg {
    max-width: 150px;
    height: auto;
  }
  .toy_img_wrapper {
    /* border: 1px solid #eee; */
    padding: unset;
    /* width: 50%; */
    /* text-align: right; */
  }
  .toyDetailsTable {
    width: 100%;
  }
  .toyDetailsTable td:first-child {
    width: 50%;
  }
  .toyDetailsTable td:last-child {
    width: 50%;
  }
  .toy_detail_wrapper {
    padding: 20px;
  }
  .toy_detail_wrapper h2 {
    font-size: 1.2rem;
    margin-bottom: .5rem
  }
  .toy_detail_wrapper p {
    font-size: 0.8rem;
    color: #333;
  }
}
