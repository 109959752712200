.navbar_wrapper {
  width: 100%;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 4px 0px;
  user-select: none;
}

.navbar {
  width: 80%;
  display: flex;
  justify-content: space-between;
  color: #333;
  margin: 0 auto;
}

.logo {
  text-decoration: none;
  padding: 8px;
  font-size: 20px;
  color: var(--primary-first);
  font-weight: 600;
  /* font-family: "NotoSansKR"; */
}

.logoutButton {
  color: #b0b0b0;
  font-weight: 600;
  padding: 7px;
  border-radius: 5px;
  /* padding: 5px; */
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: "NotoSansKR";
}

.logoutButton:hover {
  color: var(--primary-second);
}

.btn_wrapper {
  margin: 6px;
}

.btn_wrapper > a {
  color: #b0b0b0;
  margin-right: 5px;
  border-radius: 5px;
}

.btn_wrapper > a:hover {
  color: var(--primary-second);
}