@import "./variables.css";

.map_wrarpper {

}

.info_wrapper {
  display: flex;
  margin-top: 30px;
}
.info_wrapper div {
  width: 50%;
}
.info_wrapper div > span {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}
.caret {
  color: var(--primary-first);
}

/* 테이블 */

.info_wrapper table {
  width: 100%;
  border-spacing: unset;
}

.info_wrapper td:first-child {
  font-weight: 600;
  width: 20%;
  min-width: 80px;
}

/* 테이블 end */

@media (max-width: 1023px) {
  .info_wrapper {
    flex-direction: column;
    margin-top: unset;
  }
  .info_wrapper div {
    width: 100%;
    margin-top: 30px;
  }
  .info_wrapper div span {
    font-size: 18px;
  }
}