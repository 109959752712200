@import "./variables.css";

.introduce_wrapper {
  /* padding: 50px 0; */
  color: var(--primary-font-color);
  /* text-align: center; */
}

.introduce_wrapper img {
  max-width: 100%;
}

.content_wrapper {
  margin-top: 50px;
}

.content_wrapper > span {
  display: block;
  width: 100%;
  padding: 15px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  font-size: 36px;
  margin-bottom: 50px;
  text-align: center;
  box-sizing: border-box;
  /* font-weight: 700; */
}

.content_wrapper span b {
  color: var(--primary-first);
}

.writing_wrapper {
  display: flex;
  justify-content: space-between;
}

.writing_img {
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  width: 30%;
}
.writing_img > img {
  max-height: 400px;
  width: auto;
}

.writing {
  width: 65%;
  font-size: 16px;
  font-weight: 500;
}

.writing > p:first-child {
  margin-top: unset;
}

.writing > p:last-child {
  margin-bottom: unset;
  font-size: 24px;
  font-weight: 700;
  text-align: right;
}

@media (max-width: 1023px) {
  .content_wrapper span {
    font-size: 24px;
    /* font-weight: 700; */
  }

  .writing_img {
    display: none;
  }
  .writing {
    width: 100%;
    font-size: 14px;
  }
}